import * as Sentry from '@sentry/browser';
import { ServerAppError, ServerAppErrorCode } from './error';

export const SentryServerAppErrorWhiteList: Array<ServerAppErrorCode> = [
  ServerAppErrorCode.INTERNAL_SERVER_ERROR,
  ServerAppErrorCode.PROJECT_UPDATE_ERROR,
  ServerAppErrorCode.PROJECT_COMPLETE_ERROR,
  ServerAppErrorCode.EVENT_ERROR,
  ServerAppErrorCode.AKS_NOT_AVAILABLE_ERROR,
];

export const sentryEnvironmentFilter = (params: {
  event: Sentry.Event | null;
  hint: Sentry.EventHint;
}) => {
  const { event, hint } = params;
  if (!event) params;

  const environmentBlackList = ['local', 'development', 'test'];
  if (environmentBlackList.includes(import.meta.env.MODE as string)) {
    return {
      event: null,
      hint,
    };
  }

  return {
    event,
    hint,
  };
};

export const sentryServerAppErrorFilter = (params: {
  event: Sentry.Event | null;
  hint: Sentry.EventHint;
}) => {
  const { event, hint } = params;
  if (!event)
    return {
      event: null,
      hint,
    };

  const err = hint?.originalException;

  /** Skip if validation error or not in sentry whitelist */
  if (
    (err as ServerAppError)?.code &&
    !SentryServerAppErrorWhiteList.includes((err as ServerAppError)?.code)
  ) {
    return {
      event: null,
      hint,
    };
  }

  return { event, hint };
};
