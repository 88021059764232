import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import pako from 'pako';
import configs from '../../../configs';
import { IdbSuperpixelsDAO } from '../../../indexedDb/superpixels/superpixels-indexedDb.dao';
import { Composition, Tool } from '../../../models/annotation';
import { AnnotationClass, ProjectStatus } from '../../../models/project';
import { RootState } from '../../../store';
import { chunkArray } from '../../../utils/array';
import { arrayBufferToString, base64ToArrayBuffer } from '../../../utils/buffer';
import { generateRGBObject } from '../../../utils/color';
import { showNotFoundPage } from '../../../utils/error';
import { PubSubClient } from '../../../utils/pubsubClient';
import { RGB } from './../../../types/color';
import {
  fetchAnnotationWithFilter,
  fetchAnnotations,
  fetchImages,
  fetchModelMetrics,
  fetchPrediction,
  fetchProject,
  fetchSuperpixels,
  fetchSuperpixelsBoundary,
  fetchTrainable,
  fetchTrainingProgress,
  fetchTrainingStatus,
  fetchUncertainty,
  initPubSubClient,
  saveAnnotationClasses,
  updateAnnotationClass,
  updateProject,
} from './projectAsyncThunks';

const DEFAULT_ZOOM_LEVEL = 1;

export type CompositionWithImageIndex = {
  composition: Composition;
  imageIndex: number;
};

export type CompositionWithColor = Composition & {
  color: string;
};

type Pagination = {
  startIndex: number;
  endIndex: number;
  total: number; // total number of images after filtered, <= project.totalImages
};

export type ImageDataset = {
  url: string;
  initialCompositions: Composition[];
  annotations: boolean[][][];
  zoomLevel: number;
};

export enum AnnotationMode {
  SCRIBBLE = 'SCRIBBLE',
  SUPER_PIXEL = 'SUPER_PIXEL',
  ACCEPT_PREDICTION = 'ACCEPT_PREDICTION',
}

export enum TrainingStatus {
  INITIALIZING = 'INITIALIZING',
  RUNNING = 'RUNNING',
  STOP = 'STOP',
}

export interface ProjectState {
  id: string;
  name: string;
  description: string;
  status: ProjectStatus;
  trainingStatus: TrainingStatus;
  annotationMode: AnnotationMode;
  annotationClasses: AnnotationClass[];
  annotationsVisibility: boolean[];
  predictionsVisibility: boolean[];
  epistemicVisibility: boolean;
  aleatoricVisibility: boolean;
  metricVisibility: boolean;
  selectedTool: Tool;
  toolSize: number;
  selectedClass: AnnotationClass;
  imageData: { [key: string]: ImageDataset };
  predictionData: { [key: string]: string };
  epistemicUncertaintyData: { [key: string]: string };
  aleatoricUncertaintyData: { [key: string]: string };
  metricUncertaintyData: { [key: string]: number };
  superpixelsBoundaries: { [key: string]: string };
  totalImages: number;
  imageIndices: number[];
  suggestImageIndices: number[];
  selectedImage: number;
  pagination: Pagination;
  trainingProgress: number;
  undoStack: Array<CompositionWithImageIndex>;
  redoStack: Array<CompositionWithImageIndex>;
  lastUndo: CompositionWithImageIndex | null;
  lastRedo: CompositionWithImageIndex | null;
  avgDiceScore: number;
  errorDiceScore: number;
  avgPrecision: number;
  avgRecall: number;
  fetchingPageData: boolean;
  fetchingTrainingStatistics: boolean;
  fetchingUncertainty: boolean;
  fetchingImageSuggestions: boolean;
  fetchingModelMetric: boolean;
  liveUpdateOn: boolean;
  filteringAnnotatedImages: boolean;
  trainable: boolean;
  pubSubClient: PubSubClient | null;
}

const initialState: ProjectState = {
  id: '',
  name: '',
  description: '',
  status: ProjectStatus.DRAFT,
  trainingStatus: TrainingStatus.STOP,
  annotationMode: AnnotationMode.SCRIBBLE,
  annotationClasses: [],
  annotationsVisibility: [],
  predictionsVisibility: [],
  epistemicVisibility: false,
  aleatoricVisibility: false,
  metricVisibility: false,
  selectedTool: Tool.BRUSH,
  toolSize: 6,
  selectedClass: {} as AnnotationClass,
  imageData: {},
  predictionData: {},
  epistemicUncertaintyData: {},
  aleatoricUncertaintyData: {},
  metricUncertaintyData: {},
  superpixelsBoundaries: {},
  totalImages: 0,
  imageIndices: [],
  suggestImageIndices: [],
  selectedImage: -1,
  pagination: {
    startIndex: 0,
    endIndex: configs.PAGE_SIZE - 1,
    total: 0,
  },
  trainingProgress: 0,
  undoStack: [],
  redoStack: [],
  lastUndo: null,
  lastRedo: null,
  avgDiceScore: 0,
  errorDiceScore: 0,
  avgPrecision: 0,
  avgRecall: 0,
  fetchingPageData: false,
  fetchingTrainingStatistics: false,
  fetchingUncertainty: false,
  fetchingImageSuggestions: false,
  fetchingModelMetric: false,
  liveUpdateOn: false,
  filteringAnnotatedImages: false,
  trainable: false,
  pubSubClient: null,
};

export const DEFAULT_IMAGE_DATASET: ImageDataset = {
  url: '',
  zoomLevel: DEFAULT_ZOOM_LEVEL,
  initialCompositions: [],
  annotations: [],
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    changeTool: (state, action: PayloadAction<Tool>) => {
      state.selectedTool = action.payload;
    },
    changeToolSize: (state, action: PayloadAction<number>) => {
      state.toolSize = action.payload;
    },
    changeSelectedImage: (state, action: PayloadAction<number>) => {
      state.selectedImage = action.payload;
    },
    changeZoomLevel: (state, action: PayloadAction<number>) => {
      state.imageData[state.selectedImage].zoomLevel = action.payload < 1 ? 1 : action.payload;
    },
    changeClass: (state, action: PayloadAction<AnnotationClass>) => {
      state.selectedClass = action.payload;
    },
    changeMetricUncertaintyData: (state, action: PayloadAction<number[]>) => {
      const indices = state.imageIndices.slice(
        state.pagination.startIndex,
        state.pagination.endIndex + 1,
      );

      action.payload.forEach((value, imageIndex) => {
        state.metricUncertaintyData[indices[imageIndex]] = value;
      });
    },
    changeAnnotationVisibility: (
      state,
      action: PayloadAction<{ index: number; value: boolean }>,
    ) => {
      const newAnnotationsVisibility = state.annotationsVisibility.map((value, index) =>
        index === action.payload.index ? action.payload.value : value,
      );

      if (isEqual(newAnnotationsVisibility, state.annotationsVisibility)) {
        return;
      }

      state.annotationsVisibility = newAnnotationsVisibility;
    },
    toggleAllAnnotationVisibility: (state) => {
      const isAllVisible = state.annotationsVisibility.every((value) => value);

      state.annotationsVisibility = state.annotationsVisibility.map(() => !isAllVisible);
    },
    changePredictionVisibility: (
      state,
      action: PayloadAction<{ index: number; value: boolean }>,
    ) => {
      const newPredictionVisibility = state.predictionsVisibility.map((value, index) =>
        index === action.payload.index ? action.payload.value : value,
      );

      if (!isEqual(newPredictionVisibility, state.predictionsVisibility)) {
        state.predictionsVisibility = newPredictionVisibility;
      }

      state.epistemicVisibility = false;
      state.aleatoricVisibility = false;
    },
    toggleAllPredictionVisibility: (state) => {
      const isAllVisible = state.predictionsVisibility.every((value) => value);

      state.predictionsVisibility = state.predictionsVisibility.map(() => !isAllVisible);

      state.aleatoricVisibility = false;
      state.epistemicVisibility = false;
    },
    changeEpistemicVisibility: (state, action: PayloadAction<boolean>) => {
      if (state.epistemicVisibility === action.payload) {
        return;
      }
      state.epistemicVisibility = action.payload;
      state.predictionsVisibility = state.predictionsVisibility.map(() => false);
    },
    changeAleatoricVisibility: (state, action: PayloadAction<boolean>) => {
      if (state.aleatoricVisibility === action.payload) {
        return;
      }

      state.aleatoricVisibility = action.payload;
      state.predictionsVisibility = state.predictionsVisibility.map(() => false);
    },
    changeMetricVisibility: (state, action: PayloadAction<boolean>) => {
      if (state.metricVisibility === action.payload) {
        return;
      }

      state.metricVisibility = action.payload;
    },
    changePagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },
    changeAnnotationMode: (state, action: PayloadAction<AnnotationMode>) => {
      state.annotationMode = action.payload;
    },
    recordComposition: (state, action: PayloadAction<CompositionWithImageIndex>) => {
      const { imageIndex, composition } = action.payload;

      if (state.undoStack.length >= configs.ANNOTATION_HISTORY_STACK_SIZE) {
        state.undoStack.shift();
      }
      state.undoStack.push({ imageIndex, composition });
      state.redoStack = [];
    },
    undo: (state) => {
      const undoPeek = state.undoStack.pop();
      if (!undoPeek) return;
      state.lastUndo = undoPeek;
      state.redoStack.push(undoPeek);
    },
    redo: (state) => {
      const redoPeek = state.redoStack.pop();
      if (!redoPeek) return;
      state.lastRedo = redoPeek;
      state.undoStack.push(redoPeek);
    },
    changeFetchingPageData: (state, action: PayloadAction<boolean>) => {
      state.fetchingPageData = action.payload;
    },
    changeFetchingTrainingStatistics: (state, action: PayloadAction<boolean>) => {
      state.fetchingTrainingStatistics = action.payload;
    },
    changeFetchingUncertainty: (state, action: PayloadAction<boolean>) => {
      state.fetchingUncertainty = action.payload;
    },
    changeFetchingImageSuggestions: (state, action: PayloadAction<boolean>) => {
      state.fetchingImageSuggestions = action.payload;
    },
    changeFetchingModelMetric: (state, action: PayloadAction<boolean>) => {
      state.fetchingModelMetric = action.payload;
    },
    changeLiveUpdateOn: (state, action: PayloadAction<boolean>) => {
      state.liveUpdateOn = action.payload;
    },
    changeFilteringAnnotatedImages: (state, action: PayloadAction<boolean>) => {
      state.filteringAnnotatedImages = action.payload;
    },
    changeImageIndices: (state, action: PayloadAction<{ suggestImageIndices: number[] }>) => {
      const { imageIndices } = state;
      const { suggestImageIndices } = action.payload;

      const imageIndicesWithoutSuggestImageIndices = imageIndices.filter(
        (imageIndex) => !suggestImageIndices.includes(imageIndex),
      );

      state.imageIndices = [...suggestImageIndices, ...imageIndicesWithoutSuggestImageIndices];
      state.suggestImageIndices = suggestImageIndices;
      state.imageData = {};
    },
    changeModelMetrics: (
      state,
      action: PayloadAction<{
        avgDiceScore: number;
        errorDiceScore: number;
        avgPrecision: number;
        avgRecall: number;
      }>,
    ) => {
      const { errorDiceScore, avgDiceScore, avgPrecision, avgRecall } = action.payload;

      state.errorDiceScore = errorDiceScore;
      state.avgDiceScore = avgDiceScore;
      state.avgPrecision = avgPrecision;
      state.avgRecall = avgRecall;
    },
    resetImageData: (state) => {
      state.lastRedo = null;
      state.lastUndo = null;
      state.undoStack = [];
      state.redoStack = [];
      state.imageData = {};
      state.predictionData = {};
      state.aleatoricUncertaintyData = {};
      state.epistemicUncertaintyData = {};
    },
    changeProjectStatus: (state, action: PayloadAction<ProjectStatus>) => {
      state.status = action.payload;
    },
    changePubSubClient: (state, action: PayloadAction<PubSubClient | null>) => {
      state.pubSubClient = action.payload;
    },
    reset: (state) => {
      state.pubSubClient?.destruct();
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      const {
        id,
        name,
        description,
        annotationClasses,
        totalImages,
        trainingProgress,
        status,
        trainable,
      } = action.payload;

      const pagination =
        totalImages > 0
          ? {
              ...state.pagination,
              endIndex:
                totalImages <= configs.PAGE_SIZE ? totalImages - 1 : state.pagination.endIndex,
              total: totalImages,
            }
          : state.pagination;
      const imageIndices =
        state.imageIndices.length === 0 || state.imageIndices.length < totalImages
          ? [...Array(totalImages).keys()]
          : state.imageIndices;

      let selectedClass = {} as AnnotationClass;
      const currentClass = annotationClasses.find(
        (annotationClass) => annotationClass.id === state.selectedClass.id,
      );

      if (currentClass) {
        selectedClass = currentClass;
      } else if (annotationClasses[0]) {
        selectedClass = annotationClasses[0];
      }

      return {
        ...state,
        id,
        name,
        description: description ?? '',
        annotationsVisibility: annotationClasses.map(() => true),
        predictionsVisibility: annotationClasses.map(() => false),
        keepUpdating: status === ProjectStatus.UPLOADING,
        totalImages,
        imageIndices,
        pagination,
        annotationClasses,
        selectedClass,
        trainingProgress,
        status,
        trainable,
      };
    });

    builder.addCase(fetchAnnotations.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.length) {
        return;
      }

      action.payload.forEach(({ imageIndex, compositions, annotations }) => {
        const decompressedAnnotations = annotations.map<number[]>((annotation) => {
          const arrayBuffer = base64ToArrayBuffer(annotation);
          return JSON.parse(
            arrayBuffer.length ? arrayBufferToString(pako.ungzip(arrayBuffer)) : '[]',
          );
        });

        const boolAnnotationData = decompressedAnnotations.map((decompressedAnnotation) =>
          decompressedAnnotation.map((el) => !!el),
        );

        const chunkedAnnotations = boolAnnotationData.map((decompressedAnnotation) =>
          chunkArray<boolean>(decompressedAnnotation, configs.IMAGE_SIZE),
        );

        state.imageData[imageIndex] = {
          ...DEFAULT_IMAGE_DATASET,
          ...state.imageData[imageIndex],
          initialCompositions: compositions,
          annotations: chunkedAnnotations,
        };
      });
    });

    builder.addCase(fetchAnnotationWithFilter.fulfilled, (state, action) => {
      const filteredImageCount = action.payload.length;
      state.imageIndices = action.payload.map(({ imageIndex }) => imageIndex);

      if (filteredImageCount > 0) {
        state.pagination = {
          startIndex: 0,
          endIndex:
            filteredImageCount <= configs.PAGE_SIZE
              ? filteredImageCount - 1
              : configs.PAGE_SIZE - 1,
          total: filteredImageCount,
        };
        action.payload.forEach(({ imageIndex, compositions }) => {
          state.imageData[imageIndex] = {
            ...DEFAULT_IMAGE_DATASET,
            ...state.imageData[imageIndex],
            initialCompositions: compositions,
          };
        });
      }
    });

    builder.addCase(fetchImages.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.length) {
        return;
      }

      action.payload.forEach(({ imageIndex, url }) => {
        state.imageData[imageIndex] = {
          ...DEFAULT_IMAGE_DATASET,
          ...state.imageData[imageIndex],
          url,
        };
      });
    });

    builder.addCase(fetchTrainingStatus.fulfilled, (state, action) => {
      state.trainingStatus = action.payload;
    });

    builder.addCase(fetchTrainingProgress.fulfilled, (state, action) => {
      state.trainingProgress = action.payload;
    });

    builder.addCase(fetchTrainable.fulfilled, (state, action) => {
      state.trainable = action.payload;
    });

    builder.addCase(fetchModelMetrics.fulfilled, (state, action) => {
      const { avgDiceScore, errorDiceScore, avgPrecision, avgRecall } = action.payload;

      state.avgDiceScore = avgDiceScore;
      state.errorDiceScore = errorDiceScore;
      state.avgPrecision = avgPrecision;
      state.avgRecall = avgRecall;
    });

    builder.addCase(updateAnnotationClass.fulfilled, (state, action) => {
      state.annotationClasses = action.payload;

      state.selectedClass =
        action.payload.find((annotationClass) => annotationClass.id === state.selectedClass.id) ||
        action.payload[0];
    });

    builder.addCase(saveAnnotationClasses.fulfilled, (state, action) => {
      state.annotationClasses = action.payload;
      state.annotationsVisibility = action.payload.map(
        (_, index) => state.annotationsVisibility[index] || true,
      );
      state.predictionsVisibility = action.payload.map(
        (_, index) => state.predictionsVisibility[index] || false,
      );
    });

    builder.addCase(fetchPrediction.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.length) {
        return;
      }

      action.payload.forEach(({ imageIndex, url }) => {
        state.predictionData[imageIndex] = url;
      });
    });

    builder.addCase(fetchUncertainty.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.length) {
        return;
      }

      action.payload.forEach(({ imageIndex, url }) => {
        state.epistemicUncertaintyData[imageIndex] = url;
        state.aleatoricUncertaintyData[imageIndex] = url;
      });

      state.epistemicVisibility = true;
      state.aleatoricVisibility = true;
      state.metricVisibility = true;
      state.predictionsVisibility = state.predictionsVisibility.map(() => false);
    });

    builder.addCase(updateProject.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const { name, description, status } = action.payload;

      if (state.name !== name) {
        state.name = name;
      }
      if (state.description !== description) {
        state.description = description;
      }
      if (state.status !== status) {
        state.status = status;
      }
    });

    builder.addCase(fetchProject.rejected, () => {
      showNotFoundPage();
    });

    builder.addCase(initPubSubClient.fulfilled, (state, action) => {
      state.pubSubClient = action.payload;
    });

    builder.addCase(fetchSuperpixels.fulfilled, (state, action) => {
      action.payload.forEach(({ imageIndex, segmentsMatrix, segments }) => {
        const segmentsArrayBuffer = base64ToArrayBuffer(segments);
        const decompressedSegments = JSON.parse(
          segmentsArrayBuffer.length ? arrayBufferToString(pako.ungzip(segmentsArrayBuffer)) : '[]',
        );

        const segmentsMatrixArrayBuffer = base64ToArrayBuffer(segmentsMatrix);
        const decompressedSegmentsMatrix = JSON.parse(
          segmentsMatrixArrayBuffer.length
            ? arrayBufferToString(pako.ungzip(segmentsMatrixArrayBuffer))
            : '[]',
        );

        try {
          IdbSuperpixelsDAO.getInstance().upsert({
            projectId: state.id,
            imageIndex: +imageIndex,
            segments: decompressedSegments,
            segmentsMatrix: decompressedSegmentsMatrix,
          });
        } catch (error) {
          console.log("Couldn't save superpixels to indexedDB", error);
        }
      });
    });

    builder.addCase(fetchSuperpixelsBoundary.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.length) {
        return;
      }

      action.payload.forEach(({ imageIndex, url }) => {
        state.superpixelsBoundaries[imageIndex] = url;
      });
    });
  },
});

export const classToColorMap = createSelector(
  (state: RootState) => state.project.annotationClasses,
  (annotationClasses) => {
    const classToColor: Record<string, RGB> = {};

    if (annotationClasses) {
      annotationClasses.map((item) => (classToColor[item.id] = generateRGBObject(item.color)));
    }

    return classToColor;
  },
);

export const getPagingImageIndices = createSelector(
  (state: RootState) =>
    JSON.stringify({
      imageIndices: state.project.imageIndices,
      pagination: state.project.pagination,
    }),
  (param) => {
    const { imageIndices, pagination } = JSON.parse(param) as {
      imageIndices: number[];
      pagination: Pagination;
    };
    return imageIndices.slice(pagination.startIndex, pagination.endIndex + 1);
  },
);

export const getProjectUpdatable = createSelector(
  (state: RootState) => state.project.status,
  (projectStatus) => ![ProjectStatus.COMPLETED, ProjectStatus.COMPLETING].includes(projectStatus),
);

export const getFetchingInferenceResult = createSelector(
  (state: RootState) => ({
    fetchingTrainingStatistics: state.project.fetchingTrainingStatistics,
    fetchingUncertainty: state.project.fetchingUncertainty,
    fetchingModelMetric: state.project.fetchingModelMetric,
    fetchingImageSuggestions: state.project.fetchingImageSuggestions,
  }),
  ({
    fetchingTrainingStatistics,
    fetchingUncertainty,
    fetchingModelMetric,
    fetchingImageSuggestions,
  }) =>
    fetchingTrainingStatistics ||
    fetchingUncertainty ||
    fetchingModelMetric ||
    fetchingImageSuggestions,
);

export const {
  changeTool,
  changeClass,
  changeMetricUncertaintyData,
  toggleAllAnnotationVisibility,
  toggleAllPredictionVisibility,
  changeAnnotationVisibility,
  changePredictionVisibility,
  changeEpistemicVisibility,
  changeAleatoricVisibility,
  changeMetricVisibility,
  changePagination,
  changeAnnotationMode,
  changeToolSize,
  changeZoomLevel,
  changeSelectedImage,
  changeFetchingPageData,
  changeFetchingTrainingStatistics,
  changeFetchingUncertainty,
  changeFetchingImageSuggestions,
  changeFetchingModelMetric,
  changeLiveUpdateOn,
  changeFilteringAnnotatedImages,
  changeImageIndices,
  changeModelMetrics,
  changeProjectStatus,
  reset,
  undo,
  redo,
  resetImageData,
  recordComposition,
} = projectSlice.actions;

export default projectSlice.reducer;
