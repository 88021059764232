import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Image as ChakraImage,
  CircularProgress,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, ReactNode, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsPlusLg } from 'react-icons/bs';
import { MdOutlineCloudUpload } from 'react-icons/md';
import OutlineButton from '../../../../components/OutlineButton';
import configs from '../../../../configs';
import { ProjectStatus } from '../../../../models/project';
import { AppDispatch, RootState } from '../../../../store';
import { getImageDimensionsFromArrayBuffer, getNumberOfSplitImages } from '../../../../utils/image';
import { clearPreviousFailedUploads, uploadImages } from '../projectAsyncThunks';

type Props = {
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

type UploadedImage = File & {
  preview: string;
  isHovered: boolean;
};

const UploadImageModal: FC<Props> = ({ projectId, isOpen, onClose, onSubmit }) => {
  const [images, setImages] = useState<UploadedImage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<ReactNode>('');
  const dispatch = useDispatch<AppDispatch>();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const totalImages = useSelector((state: RootState) => state.project.totalImages);
  const projectStatus = useSelector((state: RootState) => state.project.status);

  const {
    isOpen: isOpenAlertModal,
    onOpen: onOpenAlertModal,
    onClose: onCloseAlertModal,
  } = useDisclosure();

  const {
    getRootProps: getDropZoneRootProps,
    getInputProps: getDropZoneInputProps,
    open: openDropZone,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/bmp': ['.bmp'],
    },
    onDrop: (acceptedFiles) => {
      setImages((images) => [
        ...images,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isHovered: false,
          }),
        ),
      ]);
    },
  });

  const verifyImages = async () => {
    let datasetSize = totalImages;
    let totalFilesSize = 0;
    let validation: {
      value: boolean;
      message: ReactNode;
    } = { value: true, message: '' };

    for (let i = 0; i < images.length; i++) {
      const arrayBuffer = await images[i].arrayBuffer();
      const { width, height } = await getImageDimensionsFromArrayBuffer(arrayBuffer);

      if (
        width * height > configs.IMAGE_DIMENSIONS_LIMIT ||
        arrayBuffer.byteLength > configs.IMAGE_MEMORY_SIZE_LIMIT
      ) {
        validation = {
          value: false,
          message: (
            <>
              File size exceeded! Please make sure each image meets the following criteria:
              <br />- Maximum dimensions: 7000 x 7000
              <br />- Maximum file size: {Math.round(configs.IMAGE_MEMORY_SIZE_LIMIT / 1000_000)}MB
            </>
          ),
        };
        break;
      }

      datasetSize += Math.ceil(width / configs.IMAGE_SIZE) * Math.ceil(height / configs.IMAGE_SIZE);
      totalFilesSize += arrayBuffer.byteLength;
    }

    if (totalFilesSize > configs.IMAGES_MEMORY_SIZE_LIMIT) {
      validation = {
        value: false,
        message: `Files size limitation exceeded! (limitation is ${Math.round(
          configs.IMAGES_MEMORY_SIZE_LIMIT / 1000_000,
        )}MB in total )`,
      };
    }

    if (datasetSize > configs.IMAGE_DATASET_SIZE_LIMIT) {
      validation = {
        value: false,
        message: (
          <>
            Dataset limitation exceeded: {datasetSize} items! <br />
            (limitation is {configs.IMAGE_DATASET_SIZE_LIMIT} in total) <br />
            <br />
            <Text fontSize="xs" color="text.text7">
              *Your dataset size is automatically calculated by the program to generate equal image
              items, each item will be {configs.IMAGE_SIZE} x {configs.IMAGE_SIZE} in size. To
              reduce the dataset size, please reduce the number of images, or choose the images with
              smaller dimension.
            </Text>
          </>
        ),
      };
    }

    return validation;
  };

  const handleCloseModal = () => {
    setImages([]);
    onClose();
  };

  const handleUploadImages = async () => {
    const validation = await verifyImages();
    if (!validation.value) {
      setWarningMessage(validation.message);
      onOpenAlertModal();
      return;
    }

    setLoading(true);

    if (projectStatus === ProjectStatus.DRAFT) {
      await dispatch(clearPreviousFailedUploads({ projectId })).unwrap();
    }

    localStorage.setItem(projectId, JSON.stringify(await getNumberOfSplitImages(images)));

    await dispatch(uploadImages({ projectId, images })).unwrap();
    setLoading(false);

    setImages([]);
    onSubmit();
  };

  const handleRemoveImage = (selectedImageIndex: number) => {
    const updatedImages = images.filter((_, index) => index !== selectedImageIndex);
    setImages(updatedImages);
  };

  return (
    <>
      <Modal size="5xl" onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent
          padding="10px"
          bg="text.text1"
          borderRadius={16}
          borderColor="text.text2"
          borderStyle="solid"
          borderWidth="0.5px"
        >
          <ModalHeader>
            <Flex justifyContent="space-between">
              <Text width="fit-content" variant="gradient">
                Upload image
              </Text>
              <Flex gap={2}>
                <Button
                  size="sm"
                  variant="gradient"
                  leftIcon={<BsPlusLg />}
                  onClick={openDropZone}
                  isDisabled={loading}
                >
                  Upload image
                </Button>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalBody>
            {loading ? (
              <Box
                height="500px"
                backdropFilter="blur(10px)"
                zIndex="10"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Center>
                  <CircularProgress isIndeterminate />
                </Center>
              </Box>
            ) : (
              <Box height="500px" overflow="auto">
                <Flex
                  {...getDropZoneRootProps({ className: 'dropzone' })}
                  borderColor="text.text2"
                  borderStyle="solid"
                  borderWidth="0.5px"
                  borderRadius="2xl"
                  minHeight="100%"
                  alignItems="center"
                  justifyContent="center"
                  padding={4}
                  {...(images.length > 0 && {
                    onClick: undefined,
                  })}
                >
                  <input {...getDropZoneInputProps()} />
                  {images.length > 0 ? (
                    <Grid templateColumns="repeat(4, 1fr)" gap={4} flex={1} alignSelf="start">
                      {images.map((file, index) => (
                        <GridItem
                          key={`${file.name}-${index}`}
                          position="relative"
                          border="2px"
                          borderStyle="solid"
                          borderColor="text.text3"
                          overflow="hidden"
                          borderRadius={8}
                          height="200px"
                          role="group"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <ChakraImage
                            src={file.preview}
                            objectFit="cover"
                            height="100%"
                            width="100%"
                            draggable={false}
                          />
                          <IconButton
                            aria-label="Remove"
                            icon={<AiOutlineCloseCircle />}
                            size="sm"
                            position="absolute"
                            top="5px"
                            right="5px"
                            onClick={() => handleRemoveImage(index)}
                            colorScheme="red"
                            cursor="pointer"
                            opacity={0}
                            pointerEvents="none"
                            _groupHover={{
                              opacity: 1,
                              pointerEvents: 'auto',
                            }}
                          />
                        </GridItem>
                      ))}
                    </Grid>
                  ) : (
                    <Stack>
                      <Center>
                        <Icon as={MdOutlineCloudUpload} boxSize="60px" color="text.text7" />
                      </Center>
                      <Box>
                        <Text fontSize="xs" as="span" cursor="pointer" variant="gradient">
                          Click to upload
                        </Text>
                        <Text fontSize="xs" as="span" color="text.text7">
                          {' '}
                          or drag & drop images
                        </Text>
                      </Box>
                    </Stack>
                  )}
                </Flex>
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Text color="text.text5" fontSize="sm">
              Supported types: PNG/JPEG(JPG)/BMP
            </Text>
            <Flex height={8}>
              <OutlineButton
                size="sm"
                width="130px"
                onClick={handleCloseModal}
                disabled={loading}
                background="background.bg4"
              >
                Cancel
              </OutlineButton>
              <Button
                variant="gradient"
                size="sm"
                width="130px"
                ml={3}
                onClick={handleUploadImages}
                isDisabled={loading || images.length === 0}
              >
                Confirm
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isOpenAlertModal}
        onClose={onCloseAlertModal}
        leastDestructiveRef={cancelRef}
        isCentered={true}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            background="background.bg2"
            color="text.text7"
            justifyContent="center"
          >
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color="main.orange4">
              Caution
            </AlertDialogHeader>
            <AlertDialogBody>{warningMessage}</AlertDialogBody>
            <AlertDialogFooter>
              <Flex height={8}>
                <Button variant="gradient" size="sm" width="130px" onClick={onCloseAlertModal}>
                  OK
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default UploadImageModal;
