import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../../components/Pagination';
import { ProjectStatus } from '../../../../../models/project';
import { AppDispatch, RootState } from '../../../../../store';
import { fetchAnnotations, fetchImages, fetchPrediction } from '../../projectAsyncThunks';
import { changePagination, getFetchingInferenceResult, resetImageData } from '../../projectSlice';

const ControlPanelPagination: FC = () => {
  const projectId = useSelector((state: RootState) => state.project.id);
  const pagination = useSelector((state: RootState) => state.project.pagination);
  const liveUpdateOn = useSelector((state: RootState) => state.project.liveUpdateOn);
  const status = useSelector((state: RootState) => state.project.status);

  const fetchingInferenceResult = useSelector<RootState>(getFetchingInferenceResult) as ReturnType<
    typeof getFetchingInferenceResult
  >;

  const { startIndex, endIndex, total } = pagination;

  const dispatch = useDispatch<AppDispatch>();

  const handlePageChange = async (startIndex: number, endIndex: number) => {
    dispatch(resetImageData());
    dispatch(changePagination({ startIndex, endIndex, total }));
    await Promise.all([
      dispatch(fetchAnnotations(projectId)).unwrap(),
      dispatch(fetchImages(projectId)).unwrap(),
    ]);

    if (status === ProjectStatus.COMPLETED) {
      dispatch(fetchPrediction());
    }
  };

  return (
    <Pagination
      total={total}
      startIndex={startIndex}
      endIndex={endIndex}
      onChange={handlePageChange}
      minimizedBreakpoint="2xl"
      isDisabled={liveUpdateOn || fetchingInferenceResult}
    />
  );
};

export default ControlPanelPagination;
